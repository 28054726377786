var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-detail-screen" },
    [
      _c("div", { staticClass: "detail-title", style: "left: -56px" }, [
        _c("img", {
          staticClass: "icon-back",
          attrs: { src: "/img/project/icon-back.png", alt: "" },
          on: { click: _vm.backToList },
        }),
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _c("div", { staticClass: "screenTitle" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("emergency.screen"))),
        ]),
        _vm.type != "add"
          ? _c("div", [
              _c("p", { staticClass: "icon", on: { click: _vm.handleClick } }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: "/img/project/icon-edit.png", alt: "" },
                }),
              ]),
            ])
          : _c("div", [
              _c("p", { staticClass: "icon", on: { click: _vm.cancleClick } }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: "/img/project/icon-close.png", alt: "" },
                }),
              ]),
            ]),
      ]),
      _vm.type == "add"
        ? _c("div", { staticClass: "edit-box" }, [
            _c("div", { staticClass: "from-box" }, [
              _c("div", { staticClass: "name" }, [
                _c("p", { staticClass: "name-title title" }, [
                  _c("span", [
                    _c("a", { staticClass: "required" }, [_vm._v("*")]),
                    _vm._v(_vm._s(_vm.$t("screen.mainTitle"))),
                  ]),
                ]),
                _c("div", { staticClass: "name-input" }, [
                  _c(
                    "div",
                    { staticClass: "name-content content" },
                    [
                      _c("el-input", {
                        staticClass: "uav-input",
                        attrs: {
                          placeholder: _vm.$t("inputPlease"),
                          maxlength: 20,
                        },
                        model: {
                          value: _vm.formDetail.screenTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.formDetail, "screenTitle", $$v)
                          },
                          expression: "formDetail.screenTitle",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "name" }, [
                _c("p", { staticClass: "name-title title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("screen.subtitle")))]),
                ]),
                _c("div", { staticClass: "name-input" }, [
                  _c(
                    "div",
                    { staticClass: "name-content content" },
                    [
                      _c("el-input", {
                        staticClass: "uav-input",
                        attrs: {
                          placeholder: _vm.$t("inputPlease"),
                          maxlength: 20,
                        },
                        model: {
                          value: _vm.formDetail.screenSubTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.formDetail, "screenSubTitle", $$v)
                          },
                          expression: "formDetail.screenSubTitle",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "name" }, [
                _c("p", { staticClass: "name-title title" }, [
                  _c("span", [
                    _c("a", { staticClass: "required" }, [_vm._v("*")]),
                    _vm._v(_vm._s(_vm.$t("screen.largeScreenTemplate"))),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "name-input",
                    on: { click: _vm.openSeletedScreen },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: _vm.formDetail.screenVO
                                ? _vm.formDetail.screenVO.name
                                : "",
                              effect: "dark",
                              placement: "top",
                            },
                          },
                          [
                            _c("div", { staticClass: "screen_input" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.formDetail.screenVO
                                      ? _vm.formDetail.screenVO.name
                                      : ""
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              false
                ? _c("div", [
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "name-title title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("screen.teamLeader"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "name-input" }, [
                        _c(
                          "div",
                          { staticClass: "name-content content" },
                          [
                            _c("el-input", {
                              staticClass: "uav-input",
                              attrs: {
                                placeholder: _vm.$t("inputPlease"),
                                maxlength: 20,
                              },
                              model: {
                                value: _vm.onDutyInfo.leader,
                                callback: function ($$v) {
                                  _vm.$set(_vm.onDutyInfo, "leader", $$v)
                                },
                                expression: "onDutyInfo.leader",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "name-title title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("screen.dutyOfficer"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "name-input" }, [
                        _c(
                          "div",
                          { staticClass: "name-content content" },
                          [
                            _c("el-input", {
                              staticClass: "uav-input",
                              attrs: {
                                placeholder: _vm.$t("inputPlease"),
                                maxlength: 20,
                              },
                              model: {
                                value: _vm.onDutyInfo.director,
                                callback: function ($$v) {
                                  _vm.$set(_vm.onDutyInfo, "director", $$v)
                                },
                                expression: "onDutyInfo.director",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "name-title title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("screen.dutyDispatch"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "name-input" }, [
                        _c(
                          "div",
                          { staticClass: "name-content content" },
                          [
                            _c("el-input", {
                              staticClass: "uav-input",
                              attrs: {
                                placeholder: _vm.$t("inputPlease"),
                                maxlength: 20,
                              },
                              model: {
                                value: _vm.onDutyInfo.dispatcher,
                                callback: function ($$v) {
                                  _vm.$set(_vm.onDutyInfo, "dispatcher", $$v)
                                },
                                expression: "onDutyInfo.dispatcher",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "name-title title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("screen.dutySectionChief"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "name-input" }, [
                        _c(
                          "div",
                          { staticClass: "name-content content" },
                          [
                            _c("el-input", {
                              staticClass: "uav-input",
                              attrs: {
                                placeholder: _vm.$t("inputPlease"),
                                maxlength: 20,
                              },
                              model: {
                                value: _vm.onDutyInfo.sectionChief,
                                callback: function ($$v) {
                                  _vm.$set(_vm.onDutyInfo, "sectionChief", $$v)
                                },
                                expression: "onDutyInfo.sectionChief",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "name-title title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("screen.dutyCommunication"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "name-input" }, [
                        _c(
                          "div",
                          { staticClass: "name-content content" },
                          [
                            _c("el-input", {
                              staticClass: "uav-input",
                              attrs: {
                                placeholder: _vm.$t("inputPlease"),
                                maxlength: 20,
                              },
                              model: {
                                value: _vm.onDutyInfo.correspondent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.onDutyInfo, "correspondent", $$v)
                                },
                                expression: "onDutyInfo.correspondent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "name-title title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("screen.dutyDriver"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "name-input" }, [
                        _c(
                          "div",
                          { staticClass: "name-content content" },
                          [
                            _c("el-input", {
                              staticClass: "uav-input",
                              attrs: {
                                placeholder: _vm.$t("inputPlease"),
                                maxlength: 20,
                              },
                              model: {
                                value: _vm.onDutyInfo.driver,
                                callback: function ($$v) {
                                  _vm.$set(_vm.onDutyInfo, "driver", $$v)
                                },
                                expression: "onDutyInfo.driver",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "name-title title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("screen.dutyPersonnel"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "name-input" }, [
                        _c(
                          "div",
                          { staticClass: "name-content content" },
                          [
                            _c("el-input", {
                              staticClass: "uav-input",
                              attrs: {
                                placeholder: _vm.$t("inputPlease"),
                                maxlength: 20,
                              },
                              model: {
                                value: _vm.onDutyInfo.people,
                                callback: function ($$v) {
                                  _vm.$set(_vm.onDutyInfo, "people", $$v)
                                },
                                expression: "onDutyInfo.people",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "name-title title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("screen.communicationPhone"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "name-input" }, [
                        _c(
                          "div",
                          { staticClass: "name-content content" },
                          [
                            _c("el-input", {
                              staticClass: "uav-input",
                              attrs: {
                                placeholder: _vm.$t("inputPlease"),
                                maxlength: 20,
                              },
                              model: {
                                value: _vm.onDutyInfo.communicationTelephone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.onDutyInfo,
                                    "communicationTelephone",
                                    $$v
                                  )
                                },
                                expression: "onDutyInfo.communicationTelephone",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "finish" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "finish-btn",
                    attrs: { loading: _vm.saveLoading, type: "primary" },
                    on: { click: _vm.save },
                  },
                  [_vm._v(_vm._s(_vm.$t("save")))]
                ),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "show-box" }, [
            _c("div", { staticClass: "show-box-top" }, [
              _c("div", { staticClass: "name" }, [
                _c("p", { staticClass: "name-title title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("screen.mainTitle")))]),
                ]),
                _c("div", { staticClass: "name-input" }, [
                  _c("div", { staticClass: "name-content title" }, [
                    _c("span", [_vm._v(_vm._s(_vm.projectInfo.screenTitle))]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "name" }, [
                _c("p", { staticClass: "name-title title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("screen.subtitle")))]),
                ]),
                _c("div", { staticClass: "name-input" }, [
                  _c("div", { staticClass: "name-content title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.projectInfo.screenSubTitle)),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "name" }, [
                _c("p", { staticClass: "name-title title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("screen.largeScreenTemplate"))),
                  ]),
                ]),
                _c("div", { staticClass: "name-input" }, [
                  _c("div", { staticClass: "name-content title" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.projectInfo.screenVO
                            ? _vm.projectInfo.screenVO.name
                            : ""
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "finish" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "finish-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.viewScreen },
                  },
                  [_vm._v(_vm._s(_vm.$t("screen.viewLargeScreen")))]
                ),
              ],
              1
            ),
          ]),
      _c("ScreenTemplate", {
        attrs: {
          show: _vm.showTemplate,
          screenVOData: _vm.formDetail.screenVO,
          isFilterate: true,
        },
        on: {
          "update:show": function ($event) {
            _vm.showTemplate = $event
          },
          changeTemplate: _vm.changeTemplate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }